import { Box } from "@mui/material";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const Main = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      let t1 = gsap.timeline();

      t1.from(".Header-Wrapper", {
        opacity: 0,
        duration: 1,
        y: "-100%",
      })
        .from(".navbar", {
          opacity: 0,
          duration: 1.5,
          y: "25%",
        })
        .from(".Hero-Section", {
          x: "-100%",
          scale: 1,
          opacity: 0,
          duration: 2,
        })
        .from(".Hero-text", {
          opacity: 0,
          y: "+=30",
        })
        .from(".OueServices img", {
          z: "100%",
          opacity: 0,
          duration: 0.4,
        })
        .from(".OueServices .About-left", {
          x: "100%",
        });
    });

    return () => ctx.revert();
  }, []);

  return (
    <Box sx={{ textAlign: "center", mt: 5 }}>
      <Box className="Hero-Section" style={{width:"100%"}}>
        {/* Responsive Images */}
        <img
          src="/HomeL.svg"
          alt="Home Desktop"
          className="responsive-img desktop"
          style={{ width: "100%" }}
        />
        <img
          src="/HomeM.svg"
          alt="Home Medium"
          className="responsive-img medium"
          style={{ width: "100%" }}
        />
        <img
          src="/HomeS.svg"
          alt="Home Mobile"
          className="responsive-img mobile"
          style={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};






// import { Box, Button } from "@mui/material"
// import { Link } from "react-router-dom"
// import DoneIcon from '@mui/icons-material/Done';
// import { useEffect } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);
// export const Main = () => {

//     useEffect(() => {
//         const ctx = gsap.context(() => {
//             let t1 = gsap.timeline();

//             t1.from(".Header-Wrapper", {
//                 opacity: 0,
//                 duration: 1,
//                 y: "-100%"
//             }).from(".navbar", {
//                 opacity: 0,
//                 duration: 1.5,
//                 y: "25%"
//             }).from(".Hero-Container", {
//                 x: "-100%",
//                 scale:1,
//                 opacity:0,
//                 duration: 2
//             }).from(".Hero-text", {
//                 opacity: 0,
//                 y: "+=30"
//             }).from(".OueServices img", {
//                 z: "100%",
//                 opacity: 0,
//                 duration: 0.4,

//             }).from(".OueServices .About-left", {
//                 x: "100%"
//             })

           
//         })

//         return () => ctx.revert()
//     }, [])
//     return (
//         <Box>
//             <Box className="Hero-Containers">
//                 <Box className="Hero-text">
//                     <h1 style={{ color: "#003567" }}>Join Hands With Us To Kill Silent Pain
//                         <br />
//                         <span style={{ color: "#18AD96" }}>Dr Vireesha Reddy BAMS</span></h1>
//                     <ul className="d-flex " style={{ margin: 0, padding: 0 }}>
//                         <li><DoneIcon className="tickicon" />Piles Treatment</li>
//                         <li><DoneIcon className="tickicon" />Fistula Treatment</li>
//                         <li><DoneIcon className="tickicon" />Fissures Treatment</li>
//                         <li><DoneIcon className="tickicon" />Constipation</li>
//                         <li><DoneIcon className="tickicon" />Kshar sutra karma Procedure</li>
//                         <li><DoneIcon className="tickicon" />Abscess Procedures</li>
//                     </ul>
//                     <Link to="/Book-Appointment" style={{ textDecoration: "none", color: "#fff" }} ><Button className="Book-appointment-btn" style={{ marginBottom: 10, marginTop: 30 }} >Book Appointment</Button></Link>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }