import axios from "axios";

// const URL = "https://clicks-gorilla.cyclic.app";
//   const URL = "https://prickly-pear-fly.cyclic.app";
const URL2 = "https://svvclinic.com";
const URL = "http://localhost:8000";
const URL3 = "https://svvclinic.com";
export const Book_Appointement = async (Data) => {
   try {
      return await axios.post(`${URL2}/GetUser.php`, Data);
   } catch (error) {
      return error.message
   }
}

export const GetAppointment = async () => {
   try {
      return await axios.get(`${URL2}/GetAppointment.php`);
   } catch (error) {
      return error.message
   }
}



export const TodayAppointments = async (Date) => {
   try {
      return await axios.post(`${URL2}/TodayAppointement.php`, Date);
   } catch (error) {
      return error.message
   }
}



// export const GetBlog = async () => {
//    try {
//       return await axios.get(`${URL}/getBlog`);
//    } catch (error) {
//       return error.message
//    }
// }


// export const Post_Blog = async (Data) => {
//    try {
//       return await axios.post(`${URL}/Post-Blog`, Data);
//    } catch (error) {
//       return error.message
//    }
// }


export const PostBlogData = async (Data) => {
   try {
      return await axios.post(`${URL3}/PostBlog.php`, Data);
   } catch (error) {
      return error.message
   }
}


export const GetBlogData = async () => {
   try {
      const response = await axios.get(`${URL3}/GetPost.php`);
      return response;
   } catch (error) {
      return error.message
   }
}

// export const GetBlogId = async (id) => {
//    try {
//       return await axios.get(`${URL}/Post-BlogId/${id}`);
//    } catch (error) {
//       return error.message
//    }
// }

export const UpdatePostData = async (Data) => {
   try {
      return await axios.post(`${URL3}/updateBlog.php`, Data);
   } catch (error) {
      return error.message
   }
}

export const DeletePost = async (Data) => {
   try {
      return await axios.post(`${URL3}/DeleteBlog.php`, Data);
   } catch (error) {
      return error.message
   }
}

// export const PostProductPage = async (data) => {
//    try {
//       return await axios.post(`${URL}/Product-page`, data);
//    } catch (error) {
//       return error.message
//    }
// }

export const VideoPost = async (Data) => {
   try {
      return await axios.post(`${URL2}/YoutubeLink.php`, Data);
   } catch (error) {
      return error.message
   }
}

export const GetVideo = async () => {
   try {
      return await axios.get(`${URL2}/GetYoutube.php`);
   } catch (error) {
      return error.message
   }
}


export const imageUploader = async (file) => {
   const response = await axios.post(`${URL}/file/upload`, file);
   return response;
}


export const AdminCheck = async (Data) => {
   try {
      return await axios.post(`${URL2}/GetAdmin.php`, Data);
   } catch (error) {
      return error.message
   }
}


// export const BlogDataSubmit = async (Data) => {
//    try {
//       return await axios.post(`${URL}/UploadBlog` , Data);
//    } catch (error) {
//       return error.message
//    }
// }




export const UpdateAppointment = async (updatedRow) => {
   return await fetch('/api/update-appointment', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify(updatedRow),
   });
 };
 