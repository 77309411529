import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

export const BlogPostPage = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [metaTitle, setMetaTitle] = useState("Default Blog Title");
    const [metaDescription, setMetaDescription] = useState("Default Blog Description");

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            const formData = new FormData();
            formData.append("route", id);

            try {
                const response = await axios.post("https://svvclinic.com/FindBlog.php", formData);
                const fetchedData = response.data;

                if (fetchedData.length > 0) {
                    setData(fetchedData);

                    // Update meta title and description dynamically
                    setMetaTitle(fetchedData.MetaTitle || fetchedData[0].Tittle || "Default Blog Title");
                    setMetaDescription(fetchedData.MetaDescription || "Discover more about this topic in our blog.");
                }
            } catch (error) {
                console.error("Error fetching blog data:", error);
            }
        };

        fetchData();
    }, [id]);

    return (
        <Box style={{ minHeight: "20vh", color: "#000 !important" }}>
            {/* Dynamic Meta Tags */}
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>

            {data.length > 0 ? (
                <Box>
                    <div className="MainImage">
                        <Box style={{ padding: 0, width: "100%", margin: "" }}>
                            <img
                                src={`https://svvclinic.com/uploads/${data[0].Thumbnail}`}
                                style={{ width: "100%", height: "369px", margin: "auto" }}
                                alt={data[0].Tittle}
                            />
                        </Box>
                    </div>
                    <div style={{ padding: 10 }}>
                        <div>
                            <h1>{data[0].Tittle}</h1>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{ __html: data[0].Content }}
                            style={{
                                padding: 10,
                                textAlign: "left",
                                minHeight: "100vh",
                                color: "#000",
                            }}
                            className="Blog-Image"
                        ></div>
                    </div>
                </Box>
            ) : (
                ""
            )}
        </Box>
    );
};
