import * as React from 'react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PositionedMenu from '../Navbar/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
export function TemporaryDrawer({ openDrawer, setDrawer }) {
  const [Show, setShow] = React.useState(false);
  const [Show1, setShow1] = React.useState(false);
  const show = () => {
    setShow(true);
    document.getElementById("Treatment").style.display = "block";

  }

  const handleshow = () => {
    setShow(false);
    document.getElementById("Treatment").style.display = "none";
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const Treatment = () => {
    document.getElementById("Treatment").style.display = "block";
  }



  const Admin = () => {

    document.getElementById("Admin").style.display = "flex";
  }

  const handleClose = () => {
    setDrawer(false);
    setShow(false);
  }

  const close = () => {
    document.getElementById("Treatment").style.display = "none";
  }
  return (
    <div className="mobile-container">
      <Drawer
        open={openDrawer}
        onClose={handleClose}
        style={{ zIndex: 4000 }}
        className="Mobile-drawer-wrapper"
      >
        <Box>

        </Box>
        <ul className="mobile-drawer"   >
          <Typography style={{ marginLeft: "auto", textAlign: "right", padding: 3, fontSize: 20 }} onClick={handleClose}>X</Typography>
          <Link to="/"  ><li onClick={handleClose} style={{ marginTop: 10 }}>
              Home 
          </li>
          </Link>
          <Link to="/About" ><li onClick={handleClose}>
              About 

          </li>
          </Link>
          <li >
            <Box style={{ display: "flex", alignItems: "center" }} onClick={show}>
              <Typography  className="Mobile-drawer-p"> Treatment</Typography>
              {Show ? <ArrowDropUpIcon onClick={handleshow} /> : <ArrowDropDownIcon onClick={show} />}
            </Box>
            <Box id="Treatment">
              <ul style={{ listStyle: "none", color: "#fff", padding: 0 }}>
                <Link to="/treatment/piles"><li onClick={handleClose} style={{ padding: 0 }}>
                  Piles
                </li>
                </Link>
                <Link to="/treatment/fissure">  <li onClick={handleClose}>
                  Fissure
                </li>
                </Link>
                <Link to="/treatment/obsteric"><li onClick={handleClose}>
                  Obsteric Fistula
                </li>
                </Link>
                <Link to="/treatment/Fistula"><li onClick={handleClose}>
                  Anal Fistula
                </li>
                </Link>

                <Link to="/treatment/constipation"><li onClick={handleClose}>
                  Constipation Treatment
                </li>
                </Link>
                <Link to="/treatment/Kshar-Sutra"><li onClick={handleClose}>
                  Kshar Sutra Karma (Procedure)
                </li>
                </Link>

              </ul>
            </Box>

          </li>
          <Link to="/video-library" ><li onClick={handleClose}>
            Video Library

          </li>
          </Link>
          <Link to="/Blog" ><li onClick={handleClose}>
            Blog

          </li>
          </Link>
          <Link to="/Contact" > <li onClick={handleClose} style={{ borderBottom: "1px dotted #f2f2f2" }}>
            Contact

          </li>
          </Link>


          <li style={{ marginTop: 20, border: "none", marginBottom: 20 }}>
            <Link to="/Book-appointment"> <Button className="Book-appointment-btn" onClick={handleClose}>Book Appointment</Button></Link>

          </li>
        </ul>
      </Drawer>
      <PositionedMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </div >
  );
}