import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GetAppointment } from "../../Services/Api";
import { Box } from '@mui/material';
import { editingStateInitializer } from '@mui/x-data-grid/internals';


export default function DataTable() {
  const [Data, setData] = React.useState([]);
  React.useEffect(() => {
    const AppointmentData = async () => {
      const response = await GetAppointment();
      const fetchedData = response.data.reverse();
      if (fetchedData) {
        const dataWithId = fetchedData.map((item, index) => ({
          Sr_no: index + 1, // Generate a unique ID (using index here for simplicity)
          ...item
        }));
        setData(dataWithId);
      }
    }
    AppointmentData();
  }, [])
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const columns = [
    { field: 'Sr_no', headerName: 'SR No.', width: 70 },
    { field: 'Name', headerName: 'name', width: 130 },
    { field: 'Email', headerName: 'email', width: 180 },
    {
      field: 'Mobile',
      headerName: 'Phone Number',
      type: 'number',
      width: 130,
    },
    {
      field: 'Subject',
      headerName: 'Subject',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
    },
    {
      field: 'Message',
      headerName: 'Description',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,

    }
    ,
    {
      field: 'DesiredDate',
      headerName: 'Appointment Date',
      sortable: true,
      width: 160,

    },
    {
      field: 'DesiredTime',
      headerName: 'Appointment Time',
      sortable: true,
      width: 160,

    },
    {
      field: 'Date',
      headerName: 'Booking Date',
      sortable: true,
      width: 160,

    }
    ,
    {
      field: 'Time',
      headerName: 'Booking Time',

      sortable: true,
      width: 160,

    }
  ];


  return (
    <div className="Table-container">
      <Box>

        <DataGrid
          style={{ height:"90vh" ,   margin:'150px 20px 20px 20px !important'}}
          rows={Data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
          checkboxSelection
        />
      </Box>
    </div>
  );
}



// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { Box, Button } from '@mui/material';

// export default function DataTable() {
//   const [Data, setData] = React.useState([]);
//   const [editedRows, setEditedRows] = React.useState({});

//   React.useEffect(() => {
//     const AppointmentData = async () => {
      
//       if (Dataa) {
//         const dataWithId = Dataa.map((item, index) => ({
//           Sr_no: index + 1, // Generate a unique ID (using index here for simplicity)
//           ...item
//         }));
//         setData(dataWithId);
//       }
//     }
//     AppointmentData();
//   }, []);

//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const handleProcessRowUpdate = (newRow, oldRow) => {
//     // Store the updated row data
//     setEditedRows((prev) => ({
//       ...prev,
//       [newRow.id]: newRow,
//     }));
//     return newRow;
//   };

//   const handleUpdate = async () => {
//     try {
//       const updates = Object.values(editedRows);
//       console.log("Updating the following rows:", updates);
//       // Call your API to update the database here
//       // Example: await UpdateAppointment(updates);
//       alert("Updated successfully!");
//       setEditedRows({}); // Clear the edited rows
//     } catch (error) {
//       console.error("Error updating rows:", error);
//       alert("Failed to update. Please try again.");
//     }
//   };

//   const columns = [
//     { field: 'Sr_no', headerName: 'SR No.', width: 70 },
//     { field: 'Name', headerName: 'Name', width: 130 },
//     { field: 'Email', headerName: 'Email', width: 180 },
//     {
//       field: 'Mobile',
//       headerName: 'Phone Number',
//       type: 'number',
//       width: 130,
//     },
//     {
//       field: 'Subject',
//       headerName: 'Subject',
//       sortable: false,
//       width: 160,
//     },
//     {
//       field: 'Message',
//       headerName: 'Description',
//       sortable: false,
//       width: 160,
//     },
//     {
//       field: 'Date',
//       headerName: 'Date',
//       editable: true,
//       sortable: true,
//       width: 160,
//     },
//     {
//       field: 'Time',
//       headerName: 'Time',
//       editable: true,
//       sortable: true,
//       width: 160,
//     },
//     {
//       field: 'Appointment_Status',
//       headerName: 'Appointment Status',
//       editable: true,
//       sortable: true,
//       width: 160,
//     }
//   ];

//   return (
//     <div className="Table-container">
//       <Box>
//         <DataGrid
//           style={{ height: "90vh", margin: '150px 20px 20px 20px !important' }}
//           rows={Data}
//           columns={columns}
//           processRowUpdate={handleProcessRowUpdate}
//           initialState={{
//             pagination: {
//               paginationModel: { page: 0, pageSize: 10 },
//             },
//           }}
//           pageSizeOptions={[5, 10, 20, 30]}
//           checkboxSelection
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           style={{ marginTop: '20px' }}
//           onClick={handleUpdate}
//           disabled={Object.keys(editedRows).length === 0}
//         >
//           Update Changes
//         </Button>
//       </Box>
//     </div>
//   );
// }




// 3

// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { GetAppointment, UpdateAppointment } from "../../Services/Api"; // Ensure you have an API method for updates
// import { Box, Button } from '@mui/material';

// export default function DataTable() {
//   const [Data, setData] = React.useState([]);
//   const [editedRows, setEditedRows] = React.useState({});

//   // Fetch data from the backend
//   React.useEffect(() => {
//     const fetchAppointmentData = async () => {
//       try {
//         const response = await GetAppointment();
//         const fetchedData = response.data.reverse();
//         if (fetchedData) {
//           const dataWithId = fetchedData.map((item, index) => ({
//             Sr_no: index + 1, // Add a serial number
//             ...item
//           }));
//           setData(dataWithId);
//         }
//       } catch (error) {
//         console.error("Error fetching appointment data:", error);
//       }
//     };
//     fetchAppointmentData();
//   }, []);

//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Handle row edits
//   const handleProcessRowUpdate = (newRow, oldRow) => {
//     setEditedRows((prev) => ({
//       ...prev,
//       [newRow.id]: newRow, // Track changes using the row ID
//     }));
//     return newRow;
//   };

//   // Update a specific row in the database
//   const handleRowUpdate = async (row) => {
//     try {
//       console.log("Updating row:", row);

//       // Make API call to update the database
//       await UpdateAppointment(row);

//       alert(`Row with ID ${row.id} updated successfully!`);
//       setEditedRows((prev) => {
//         const newEditedRows = { ...prev };
//         delete newEditedRows[row.id]; // Remove the row from editedRows after successful update
//         return newEditedRows;
//       });
//     } catch (error) {
//       console.error("Error updating row:", error);
//       alert("Failed to update. Please try again.");
//     }
//   };

//   // Define table columns
//   const columns = [
//     { field: 'Sr_no', headerName: 'SR No.', width: 70 },
//     { field: 'Name', headerName: 'Name', width: 130 },
//     { field: 'Email', headerName: 'Email', width: 180 },
//     {
//       field: 'Mobile',
//       headerName: 'Phone Number',
//       type: 'number',
//       width: 130,
//     },
//     {
//       field: 'Subject',
//       headerName: 'Subject',
//       sortable: false,
//       width: 160,
//     },
//     {
//       field: 'Message',
//       headerName: 'Description',
//       sortable: false,
//       width: 160,
//     },
//     {
//       field: 'Date',
//       headerName: 'Date',
//       editable: true, // Allow editing
//       width: 160,
//     },
//     {
//       field: 'Time',
//       headerName: 'Time',
//       editable: true, // Allow editing
//       width: 160,
//     },
//     {
//       field: 'Appointment_Status',
//       headerName: 'Appointment Status',
//       editable: true, // Allow editing
//       width: 160,
//     },
//     {
//       field: 'actions',
//       headerName: 'Actions',
//       width: 160,
//       renderCell: (params) => (
//         <Button
//           variant="contained"
//           color="primary"
//           size="small"
//           onClick={() => handleRowUpdate(params.row)}
//         >
//           Update
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <div className="Table-container">
//       <Box>
//         <DataGrid
//           style={{ height: "90vh", margin: '150px 20px 20px 20px !important' }}
//           rows={Data}
//           columns={columns}
//           processRowUpdate={handleProcessRowUpdate} // Handle row edits
//           initialState={{
//             pagination: {
//               paginationModel: { page: 0, pageSize: 10 },
//             },
//           }}
//           pageSizeOptions={[5, 10, 20, 30]}
//           checkboxSelection
//         />
//       </Box>
//     </div>
//   );
// }





// 4


// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import dayjs from 'dayjs';
// import { GetAppointment, UpdateAppointment } from "../../Services/Api";
// import { Box, Button } from '@mui/material';

// export default function DataTable() {
//   const [Data, setData] = React.useState([]);
//   const [editedRows, setEditedRows] = React.useState({});

//   // Fetch data from the backend
//   React.useEffect(() => {
//     const fetchAppointmentData = async () => {
//       try {
//         const response = await GetAppointment();
//         const fetchedData = response.data.reverse();
//         if (fetchedData) {
//           const dataWithId = fetchedData.map((item, index) => ({
//             Sr_no: index + 1, // Add a serial number
//             ...item,
//             Date: dayjs(item.Date), // Ensure the date is compatible with DatePicker
//           }));
//           setData(dataWithId);
//         }
//       } catch (error) {
//         console.error("Error fetching appointment data:", error);
//       }
//     };
//     fetchAppointmentData();
//   }, []);

//   // Handle row updates
//   const handleProcessRowUpdate = (newRow, oldRow) => {
//     setEditedRows((prev) => ({
//       ...prev,
//       [newRow.id]: newRow,
//     }));
//     return newRow;
//   };

//   const handleRowUpdate = async (row) => {
//     try {
//       console.log("Updating row:", row);

//       // Make API call to update the database
//       await UpdateAppointment(row);

//       alert(`Row with ID ${row.id} updated successfully!`);
//       setEditedRows((prev) => {
//         const newEditedRows = { ...prev };
//         delete newEditedRows[row.id];
//         return newEditedRows;
//       });
//     } catch (error) {
//       console.error("Error updating row:", error);
//       alert("Failed to update. Please try again.");
//     }
//   };

//   // Custom date cell editor
//   const renderDateEditCell = (params) => {
//     const handleDateChange = (newValue) => {
//       params.api.setEditCellValue({
//         id: params.id,
//         field: params.field,
//         value: newValue,
//       });
//     };

//     return (
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DatePicker
//           value={params.value}
//           onChange={handleDateChange}
//           format="DD-MM-YYYY" // Format for the displayed date
//         />
//       </LocalizationProvider>
//     );
//   };

//   // Define table columns
//   const columns = [
//     { field: 'Sr_no', headerName: 'SR No.', width: 70 },
//     { field: 'Name', headerName: 'Name', width: 130 },
//     { field: 'Email', headerName: 'Email', width: 180 },
//     {
//       field: 'Mobile',
//       headerName: 'Phone Number',
//       type: 'number',
//       width: 130,
//     },
//     {
//       field: 'Subject',
//       headerName: 'Subject',
//       sortable: false,
//       width: 160,
//     },
//     {
//       field: 'Message',
//       headerName: 'Description',
//       sortable: false,
//       width: 160,
//     },
//     {
//       field: 'Date',
//       headerName: 'Date',
//       editable: true,
//       width: 160,
//       renderEditCell: renderDateEditCell, // Custom date picker for editing
//     },
//     {
//       field: 'Time',
//       headerName: 'Time',
//       editable: true,
//       width: 160,
//     },
//     {
//       field: 'Appointment_Status',
//       headerName: 'Appointment Status',
//       editable: true,
//       width: 160,
//     },
//     {
//       field: 'actions',
//       headerName: 'Actions',
//       width: 160,
//       renderCell: (params) => (
//         <Button
//           variant="contained"
//           color="primary"
//           size="small"
//           onClick={() => handleRowUpdate(params.row)}
//         >
//           Update
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <div className="Table-container">
//       <Box>
//         <DataGrid
//           style={{ height: "90vh", margin: '150px 20px 20px 20px !important' }}
//           rows={Data}
//           columns={columns}
//           processRowUpdate={handleProcessRowUpdate}
//           initialState={{
//             pagination: {
//               paginationModel: { page: 0, pageSize: 10 },
//             },
//           }}
//           pageSizeOptions={[5, 10, 20, 30]}
//           checkboxSelection
//         />
//       </Box>
//     </div>
//   );
// }
