 import {Routes , Route , BrowserRouter} from "react-router-dom" ;
import {Box} from "@mui/material"
import { Header } from './Component/Navbar/Header';
import { Home } from './Component/Home/Home';
import {Protected} from "./Component/Protected/Protected"
import './style.css'
import { Main } from "./Component/About/Main";
import { BookAppointment  } from "./Component/Book-Appointment/Book-Appointment";
import {Blog} from "./Component/Blog/Blog";
import { Contact } from "./Component/Contact/Contact";
import { AdminLogin } from "./Component/Admin/AdminLogin";
import DataTable from "./Component/Admin/Appointments";
import { Constipation, Fissure, Fistula, KashraSutra, Obsteric, Piles  } from "./Component/Product/main";
import { BlogPostPage } from "./Component/Blog/blog-post-page"; 
import { Doctor } from "./Component/Doctor/Doctor";
import Footer from "./Component/footer/footer";
import { AdminDashboard } from "./Component/Admin/AdminDashboard";
import TodayAppointmentData from "./Component/Admin/TodayAppointments";
import TodayBlogData from "./Component/Admin/TotalBlogs"
import { EditBlogData } from "./Component/Blog/edit-blog";
import { PostVideo } from "./Component/Doctor/Post-Video";
import { Loader } from "./Component/Loader";
import { useEffect, useState } from "react";
import Axios from "axios";
import { Blog_post } from "./Component/waste/Blog-post-page2";

 
function App() {

  const [Loading , setLoading] = useState(false) ; 

 
 

  useEffect(() => {
    Axios.interceptors.request.use((config) => {
      setLoading(true) ; 
      return config ;
    } , (error) => {
      return Promise.reject(error)
    })

  Axios.interceptors.response.use((config) => {
      setLoading(false) ; 
      return config ;
    } , (error) => {
      return Promise.reject(error)
    })
  } , [Loading])

 
  return (
    <Box>
      
      <BrowserRouter>
      <Header/>
      <Loader show={Loading}/>
      <Box style={{marginTop:130}}>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/About" element={<Main/>} />
      <Route path="/Book-Appointment" element={<BookAppointment/>} />
      <Route path="/Blog" element={<Blog/>} />
      <Route path="/Contact" element={<Contact/>} />
      <Route path="Admin" element={<AdminLogin/>} />
      <Route path="/Post-Blog" element={< Protected Component={Blog_post}/>} /> 
      <Route path="/Total-appointements" element={<Protected Component={DataTable}/>} />
      <Route path="/Treatment/Piles" element={<Piles/>} />
      <Route path="/Treatment/Fissure" element={<Fissure/>} />
      <Route path="/Treatment/Obsteric" element={<Obsteric/>} />
      <Route path="/Treatment/Fistula" element={<Fistula/>} />
      <Route path="/Treatment/Constipation" element={<Constipation/>} />
      <Route path="/Treatment/Kshar-Sutra" element={<KashraSutra/>} />
      <Route path="/blog/:id" element={<BlogPostPage/>} />
      <Route path="/video-library" element={<Doctor/>} />
      <Route path="/admindashboard" element={< Protected Component={AdminDashboard}/>} />
      <Route path="/today-appointements" element={<Protected Component={TodayAppointmentData}/>} />
      <Route path="/total-blogs" element={<Protected Component={TodayBlogData} />} />
      <Route path="/Blog-edit/:id" element={< Protected Component={EditBlogData} />}/>
      <Route path="/post-video" element={<PostVideo/>}/>
      <Route path="*" element={<Box style={{marginTop:200 , height:"50vh",textAlign : "center"}}><h1>Page Not Found </h1> <h2 style={{color:"red"}}>404 ERROR</h2></Box>}/>
      
  </Routes>
  </Box>
<Footer/>
  </BrowserRouter>
    </Box>
  );
}

export default App;
