import { Box } from "@mui/material"
 
import { Main } from "./Main"
import { About } from "./About"
import { Patients } from "./Our-patients"
import { OurServices } from "./OurServices"
import { Testimonial } from "./PatientTestimonial"
import { useEffect } from "react"
import { Helmet } from "react-helmet"

export const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <Box>
              <Helmet>
                <title>
                    SVV Clinic
                </title>
      <meta
      name="description"
      content="Welcome to SVV Clinic, where the ancient art of Ayurveda meets modern healthcare, creating a sanctuary for holistic well-being. Nestled in the vibrant city of Hyderabad, SVV Clinic is the manifestation of a rich legacy passed down through generations."
    />
      </Helmet>
            <Box>
                <Main/>
                <About/>
                <Patients/>
                <OurServices/>
                <Testimonial/>
            </Box>
        </Box>
    )
}