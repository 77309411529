import { Box, Grid, Typography, Button } from "@mui/material"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { useEffect, useState } from "react";
import { Book_Appointement } from "../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "../Book-Appointment/Dialoge";
import moment from "moment";
const Data = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    msg: "",
    Desired_Date: moment().format('YYYY-MM-DD'), // Default to today's date
    Desired_Time: moment().format('HH:mm'), // Default to current time
    Date: moment().format('YYYY-MM-DD'), // Set current date
    Time: moment().format('HH:mm'), // Set current time
}

var regexname = /^[a-zA-Z]+[a-zA-Z\s]*?[^0-9]$/;
var emailregex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var msgregex = /^[a-zA-Z0-9@.]+[a-zA-Z0-9\s]*?$/;

export const Contact = () => {

    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);

    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });
    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else if (!UserData.subject || !msgregex.test(!UserData.subject)) {
            toast.error("Enter Your Subject")
        }
        else {
            const data = new FormData();
            data.append("Name", UserData.name);
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Desired_Time", UserData.Desired_Time); // Send the desired time
            data.append("Desired_Date", UserData.Desired_Date); // Send the desired date
            data.append("Time", UserData.Time); // Send the time
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const currentYear = moment().format('YYYY');
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().add(3, 'months').endOf('month').format('YYYY-MM-DD');
    return (

        <Box>
            <Box className="About-Bg blog-bg" style={{ marginTop: 0 }}>
                <h1 >Contact Us</h1>
                <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page">Home</a> > <a href="/Contact" title="Contact Us Page">Conatct Us</a></Typography>
            </Box>
            <Box className="Contact-container" style={{ display: "flex", marginTop: 30 }}>


                <Box className="Contact-Box"  >
                    <PhoneIcon style={{ fontSize: 60, color: "#18AD96", marginBottom: 20 }} />
                    <Typography variant="h5" >CONTACT DETAILS</Typography>
                    <Box style={{ marginTop: 20, textAlign: "left" }}>
                        <span>Please call or email us. we will be happy to assist you.</span><br />
                        <Typography variant="h6"> <a href="tel:9518312766" >+919518312766</a></Typography>
                    </Box>
                </Box>



                <Box className="Contact-Box" >
                    <LocationOnIcon style={{ fontSize: 60, color: "#18AD96", marginBottom: 20 }} />
                    <Typography variant="h5">LOCATION</Typography>
                    <Box style={{ marginTop: 20, textAlign: "left" }}>
                        <Typography variant="span">Ground floor, H.No: 2, 56/9, Meridian School Rd, Beside Mallikarjuna SuperMarket, opp. Vegetable Market, Ayyappa Society, Hyderabad, Telangana 500081.  Phone Number: 9518312766</Typography >
                    </Box>
                </Box>

            </Box>

            <Box className="BookAppointment-Box" style={{ margin: "40px auto auto" }}>
                <h2>Book An Appointment</h2>
                <p>Please submit your details here and our team will get back soon.</p>
                <Box className="Book-appointment-input">
                    <Grid container spacing={5}>
                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                            <Box>
                                <input type="text" name='name' placeholder="Your Name : " className="contact-input" onChange={Book} ></input>
                            </Box>
                        </Grid>

                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                            <Box>
                                <input type="text" name='email' placeholder="Your Email :" className="contact-input" onChange={Book}></input>
                            </Box>
                        </Grid>

                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                            <Box>
                                <input type="number" name='phone' min="1" max="10" placeholder="Your Phone : " className="contact-input" onChange={Book}></input>
                            </Box>
                        </Grid>

                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                            <Box>
                                <input type="text" name='subject' placeholder="Subject :" className="contact-input" onChange={Book}></input>
                            </Box>
                        </Grid>

                        <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
                            <Box>
                                <textarea type="text" name='msg' placeholder="Message (optional)" onChange={Book} rows={5} cols={50} ></textarea>
                            </Box>
                        </Grid>
                        

                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                            <Box>
                                <input
                                    type="date"
                                    name="Desired_Date"
                                    value={UserData.Desired_Date}
                                    onChange={(e) => setData({ ...UserData, Desired_Date: e.target.value })}
                                    min={startDate}
                                    max={endDate}
                                    className="contact-input"
                                    onFocus={() => document.querySelector('input[type="date"]').showPicker()} // Trigger date picker on focus
                                />
                            </Box>
                        </Grid>
                        {/* Time Picker */}
                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                            <Box>
                                <input
                                    type="time"
                                    name="Desired_Time"
                                    value={UserData.Desired_Time}
                                    onChange={(e) => setData({ ...UserData, Desired_Time: e.target.value })}
                                    className="contact-input"
                                    onFocus={() => document.querySelector('input[type="time"]').showPicker()} // Trigger time picker on focus
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Button className="Book-appointment-btn" onClick={Submit} style={{ marginTop: 20 }}>Book Appointment</Button>
                </Box>
            </Box>
            <Box style={{ textAlign: "center" }} >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1948734.7485134404!2d75.95004305625!3d17.4538473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91f2f4e6be4d%3A0x4f458c022c5c3973!2sSVV%20Clinic%20-%20Best%20Ayurvedic%20Piles%2C%20Fistula%2C%20Fissures%2C%20Haemorrhoid%20Treatment%20Clinic!5e0!3m2!1sen!2sin!4v1687089003718!5m2!1sen!2sin" width={"80%"} height={"400px"} style={{ margin: "auto", margin: "30px auto" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <Box style={{ padding: "0 10%", textAlign: "left" }}>
                    <Typography variant="h6" style={{ fontWeight: 550, marginBottom: 40, fontFamily: "Poppins", fontSize: 18 }}>Welcome to the SVV Clinic website, where you can know more about Dr. Vireesha Bogireddy D/o.  Dr. Nagireddy Bogireddy a renowned specialist in anorectal problems and a master of Kshar Sutra karma procedures for over 30 years. SVV Clinic is a single stop solution for all your anorectal problems.</Typography>
                </Box>
            </Box>
            <Box>
                <AlertDialog open={open} setOpen={setOpen} />
            </Box>
            <ToastContainer />


        </Box>
    )
}